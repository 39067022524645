import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialogRef } from '@angular/material';
import { DataService } from '../data.service';
import { DragulaService } from "ng2-dragula";
import { AngularFireStorage, AngularFireUploadTask } from "angularfire2/storage";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as _ from "lodash";
import { SharedProvider } from '../shared/shared';
import { LoaderService } from '../shared/loader';
import { AddSpaceService } from '../appservices/addspace.service';
import { FormCanDeactivate } from '../form-can-deactivate';
import * as firebase from 'firebase/app';
import { AppConstants } from '../app.constants';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
	selector: 'app-fare-details',
	templateUrl: './fare-details.component.html',
	styleUrls: ['./fare-details.component.scss']
})
export class FareDetailsComponent extends FormCanDeactivate implements OnInit {

	@Input() appstatus: boolean;
	@Input('typeofSpace') type: string;
	@Input('action') action: string;
	@Input('key') key: string;
	//   @Output() spaceTypeData = new EventEmitter<Event>();
	downloadURLs: any = [];
	fileStoragePath: any;
	alltaskList: any[] = [];
	allPercentage: Observable<number>[] = [];
	uploadPercentageList: any[] = [];
	cabin: any = { photos: [] };
	confroom: any = { photos: [] };
	uploadPercent: any;
	storageUrl: any;
	spaceType: string;
	fareDetailsOthers: FormGroup;
	fareDetailsConf: FormGroup;
	spaceDetails: any = {};
	task: AngularFireUploadTask;
	isHovering: boolean;
	private imageChangedEvent = null;
	croppedImage: string;
	imageCroppingDone: boolean = true;
	croppedImageSize: string = "";
	constructor(
		private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogComponent>, private data: DataService,
		private storage: AngularFireStorage, private dragulaService: DragulaService, public shared: SharedProvider,
		public addspaceService: AddSpaceService, private loader: LoaderService
	) {
		super();
		this.storageUrl =
			AppConstants.CONSTANTS[
				AppConstants.CONSTANTS.currentEnvironment
			].firebase.storageBucket;
		this.fileStoragePath = AppConstants.CONSTANTS.filestorage.filepath;
		dragulaService.createGroup("cabin-photos", {
			revertOnSpill: true
		});
		dragulaService.createGroup("confroom-photos", {
			revertOnSpill: true
		});
	}
	@ViewChild('form', { static: false })
	form: NgForm;

	ngOnInit() {
		this.fareDetailsOthers = this.formBuilder.group({
			name: ['', Validators.required],
			seats: ['', Validators.required],
			ratepermonth: ['', Validators.required],
			rateperday: [''],
			deposit: ['', Validators.required]
			// notes:['']
		});

		this.fareDetailsConf = this.formBuilder.group({
			name: ['', Validators.required],
			seats: ['', Validators.required],
			rateperhourConf: ['', Validators.required],
			rateperdayConf: [''],
			projector: ['', Validators.required],
			tv: ['', Validators.required],
			whiteboard: ['', Validators.required],
			audio: ['', Validators.required],
			suitedFor: ['', Validators.required],
			system: ['', Validators.required]
			// notes:['']
		});
		if (this.action == 'update') {
			let savedData = this.shared.getSpaceData();
			let data = savedData.spaceData.subSpaceDetails[this.key];
			if (this.type == 'Open Desks' || this.type == 'Private Cabin') {
				this.fareDetailsOthers.patchValue({
					name: data.name,
					seats: data.seats,
					ratepermonth: data.ratepermonth,
					rateperday: data.rateperday,
					rateperhour: data.rateperhour,
					deposit:data.deposit
					// notes:data.notes
				});
				this.cabin.photos = data.photos;
				this.shared.setImageLink(this.cabin.photos);
			}
			else if (this.type == 'Meeting / Conference Room') {
				this.fareDetailsConf.patchValue({
					name: data.name,
					seats: data.seats,
					rateperhourConf: data.rateperhourConf,
					rateperdayConf: data.rateperdayConf,
					projector: data.projector,
					tv: data.tv,
					whiteboard: data.whiteboard,
					audio: data.audio,
					suitedFor: data.suitedFor,
					system: data.system
					// notes:data.notes
				});
				this.confroom.photos = data.photos;
				this.shared.setImageLink(this.confroom.photos);
			}
		}
		if (this.appstatus) {
			this.fareDetailsConf.disable();
			this.fareDetailsOthers.disable();
		}
	}
	ngOnDestroy() {
		this.dragulaService.destroy("cabin-photos");
		this.dragulaService.destroy("confroom-photos");
		this.shared.setImageLink([]);
	}

	save() {
		if (this.type == "Meeting / Conference Room") {
			this.spaceDetails = this.fareDetailsConf.value;
			this.spaceDetails.type = this.type;
			this.spaceDetails.photos = this.shared.getImageLink();
			//only one of projector or TV should be selected
			if (this.spaceDetails.projector == "Yes" && this.spaceDetails.tv == "Yes") {
				alert("Only one of projector or TV should be selected");
			} else {
				this.saveSpaceType(this.spaceDetails);
			}
		}
		else {
			this.spaceDetails = this.fareDetailsOthers.value;
			this.spaceDetails.type = this.type;
			this.spaceDetails.photos = this.shared.getImageLink();
			this.saveSpaceType(this.spaceDetails);
		}
	}


	saveSpaceType(input: any) {
		let d = this.shared.getSpaceData();
		const space = {
			applicationId: d.applicationId,
			applicationKey: d.applicationKey,
			action: this.action,
			subSpaceKey: this.key,
			subSpaceDetails: input
		};
		this.loader.display(true);
		this.addspaceService
			.addOrRemoveSubSpace(space)
			.then((spacevalue: any) => {
				this.shared.setSpaceData(spacevalue);
				this.dialogRef.close();
				this.loader.display(false);
			})
			.catch(err => {
				console.log(err);
				this.loader.display(false);
			});
	}


	toggleHover(event: boolean) {
		this.isHovering = event;
	}

	getSpaceName () {
		if (this.type == "Meeting / Conference Room" && this.fareDetailsConf.valid) {
			return this.fareDetailsConf.value.name;
		}
		else if ((this.type == "Open Desks" || this.type == "Private Cabin") && this.fareDetailsOthers.valid) {
			return this.fareDetailsOthers.value.name;;
		}
	}

	disableFileUpload() {
		if (this.type == "Meeting / Conference Room" && this.fareDetailsConf.valid) {
			return false;
		}
		else if ((this.type == "Open Desks" || this.type == "Private Cabin") && this.fareDetailsOthers.valid) {
			return false;
		}
		else {
			return true;
		}
	}

	disableSave() {
		if (this.type == "Meeting / Conference Room" && this.fareDetailsConf.valid && this.confroom.photos.length > 0) {
			return false;
		}
		else if ((this.type == "Open Desks" || this.type == "Private Cabin") && this.fareDetailsOthers.valid && this.cabin.photos.length > 0) {
			return false;
		}
		else {
			return true;
		}
	}

	uploadFileForCropping(event) {
		// console.log("Image for cropping",event);
		if (!this.disableFileUpload()) {
			const filelist = event;
			if (filelist.length > 1) {
				console.error("Select only one file at a time");
				alert("Upload only one file at a time.")
				return;
			}
			this.imageChangedEvent = event;
			this.imageCroppingDone = false;
		}
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.croppedImageSize = event.width + " px X " + event.height + " px"; 
	}
	async doneCropping () {
		// console.log("this.croppedImage",this.croppedImage);
		// this.croppedImage = null;
		await this.uploadFileBase64();
		this.imageCroppingDone = true;
		this.imageChangedEvent = null;
	}
	imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
	}

	uploadFileBase64() {
		const filename = this.getSpaceName() +  "_"  + (new Date().getTime());
		// console.log("fileName",filename);
		const filePath = this.fileStoragePath + "/" + filename;
		const customMetadata = { app: "Space Rocket App", name: filename,contentType:'image/jpg' };
		const fileRef = this.storage.ref(filePath);
		const task = fileRef.putString(this.croppedImage.split(',')[1],'base64',{customMetadata});
		const _percentage$ = task.percentageChanges();
		this.alltaskList.push(task);
		this.allPercentage.push(_percentage$);
		task.then(ress => {
			this.getURL(ress);
		});

		// observe percentage changes
		this.uploadPercent = task.percentageChanges();
		this.alltaskList.push(task);
		// get notified when the download URL is available
		const ts = task
			.snapshotChanges()
			.pipe(
				tap(snap => {
					console.log("snap",snap);
					var index = _.findIndex(this.uploadPercentageList, {
						filename: snap.metadata.customMetadata.name
					});
					// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
					if (index >= 0) {
						const updatedpercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.customMetadata.name
						};
						this.uploadPercentageList.splice(index, 1, updatedpercentage);
					} else {
						const uploadPercentage = {
							percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
							state: snap.state,
							filename: snap.metadata.customMetadata.name
						};
						this.uploadPercentageList.push(uploadPercentage);
					}
					if (snap.bytesTransferred === snap.totalBytes) {
					}
				})
			)
			.subscribe();
	}

	uploadFile(event) {
		// if (!this.disbaleFileUpload()) {
		this.downloadURLs = [];
		const filelist = event;

		for (const file of filelist) {
			if (file.size > 5242880) {
				console.error("File size is greater than 5 MB ");
				return;
			}

			if (file.type.split("/")[0] !== "image") {
				console.error("unsupported file type :( ");
				return;
			}
			const filename = `${new Date().getTime()}_${file.name}`;
			const filePath = this.fileStoragePath + "/" + filename;
			const customMetadata = { app: "Space Rocket App", name: filename };
			const task = this.storage.upload(filePath, file, { customMetadata });
			const fileRef = this.storage.ref(filePath);
			const _percentage$ = task.percentageChanges();
			this.alltaskList.push(task);
			this.allPercentage.push(_percentage$);
			task.then(ress => {
				this.getURL(ress);
			});

			// observe percentage changes
			this.uploadPercent = task.percentageChanges();
			this.alltaskList.push(task);
			// get notified when the download URL is available
			const ts = task
				.snapshotChanges()
				.pipe(
					tap(snap => {
						var index = _.findIndex(this.uploadPercentageList, {
							filename: snap.metadata.customMetadata.name
						});
						// let avl_list = this.uploadPercentageList.find(percent => percent.filename === snap.metadata.customMetadata.name)
						if (index >= 0) {
							const updatedpercentage = {
								percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
								state: snap.state,
								filename: snap.metadata.customMetadata.name
							};
							this.uploadPercentageList.splice(index, 1, updatedpercentage);
						} else {
							const uploadPercentage = {
								percentage: (snap.bytesTransferred / snap.totalBytes) * 100,
								state: snap.state,
								filename: snap.metadata.customMetadata.name
							};
							this.uploadPercentageList.push(uploadPercentage);
						}
						if (snap.bytesTransferred === snap.totalBytes) {
						}
					})
				)
				.subscribe();
		}
		// }
	}

	getURL(ress) {
		let link;
		ress.ref.getDownloadURL().then(l => {
			link = l;
			var index = _.findIndex(this.uploadPercentageList, {
				filename: this.getfilename(link)
			});
			if (index >= 0) {
				this.uploadPercentageList.splice(index, 1);
			}
			this.downloadURLs = this.downloadURLs.concat([link]);
			// this.allPercentage.forEach(val => {
			// })
			if (this.type == 'Open Desks' || this.type == 'Private Cabin') {
				if (this.cabin.photos && this.cabin.photos.length > 0) {
					// this.space.photos = this.space.photos.push(url);
					this.cabin.photos = _.union(this.cabin.photos, this.downloadURLs);
					this.shared.setImageLink(this.cabin.photos);
					// this.createOrAddPhotos("add", this.space.photos);
				} else {
					// const updatedImage = [...this.downloadURLs];
					this.cabin.photos = [...this.downloadURLs];
					this.shared.setImageLink(this.cabin.photos);
					// if (this.space.spaceId) {
					// this.createOrAddPhotos("add", this.space.photos);
					// } 
				}
			}
			else {
				if (this.confroom.photos && this.confroom.photos.length > 0) {
					// this.space.photos = this.space.photos.push(url);
					this.confroom.photos = _.union(this.confroom.photos, this.downloadURLs);
					this.shared.setImageLink(this.confroom.photos);
					// this.createOrAddPhotos("add", this.space.photos);
				} else {
					// const updatedImage = [...this.downloadURLs];
					this.confroom.photos = [...this.downloadURLs];
					this.shared.setImageLink(this.confroom.photos);
					// if (this.space.spaceId) {
					// this.createOrAddPhotos("add", this.space.photos);
					// } 
				}
			}
		});
		// return link;
	}
	getfilename(fullpath) {
		let storagepath = decodeURIComponent(fullpath).split(
			this.storageUrl + "/o/" + this.fileStoragePath
		)[1];
		return storagepath.substring(1, storagepath.indexOf("?"));
	}

	deleteCabinPics(name) {
		this.loader.display(true);
		if (this.cabin.photos.length === 1) {
			/*
				If there is only one photo we just remove it only from dom.
				If the user try to save the changes we will show the error
				pop up
			*/
			this.cabin.photos = [];
			this.shared.setImageLink(this.cabin.photos);
			this.loader.display(false);
			return;
		}
		const updatedphotos = [...this.cabin.photos];
		updatedphotos.splice(updatedphotos.indexOf(name), 1);
		this.cabin.photos = [...updatedphotos];
		this.shared.setImageLink(this.cabin.photos);
		let storagepath = name.split(this.storageUrl + "/o/")[1];
		const path = decodeURIComponent(
			storagepath.substring(0, storagepath.indexOf("?"))
		);
		const storageRef = firebase.storage().ref();
		storageRef
			.child(path)
			.delete()
			.then(val => {
				console.log("Image delete success");
				this.loader.display(false);
			})
			.catch(err => {
				console.log("Image delete failed");
				this.loader.display(false);
			});
	}

	deleteConfPics(name) {
		this.loader.display(true);
		if (this.confroom.photos.length === 1) {
			/*
				If there is only one photo we just remove it only from dom.
				If the user try to save the changes we will show the error
				pop up
			*/
			this.confroom.photos = [];
			this.shared.setImageLink(this.confroom.photos);
			this.loader.display(false);
			return;
		}
		const updatedphotos = [...this.confroom.photos];
		updatedphotos.splice(updatedphotos.indexOf(name), 1);
		this.confroom.photos = [...updatedphotos];
		this.shared.setImageLink(this.confroom.photos);
		let storagepath = name.split(this.storageUrl + "/o/")[1];
		const path = decodeURIComponent(
			storagepath.substring(0, storagepath.indexOf("?"))
		);
		const storageRef = firebase.storage().ref();
		storageRef
			.child(path)
			.delete()
			.then(val => {
				console.log("Image delete success");
				this.loader.display(false);
			})
			.catch(err => {
				console.log("Image delete failed");
				this.loader.display(false);
			});
	}

	close() {
		this.spaceDetails = {};
		// this.data.newSpaceData(this.spaceDetails);
		this.dialogRef.close();
	}

}
